/** @jsx h */

"use strict";

import * as tripplanerItemsStore from './tripplaner-items-store';
import * as subscribers from '../subscribe';
import initModulesInScope from '../initModulesInScope';
import { h, render } from 'preact';
import Teaser from './tripplaner-slider-teaser';

export function initInScope($scope) {
    function TripPlanerSlider ($ele) {

        let self = this;

        this.$ele = $ele;
        this.$ele.data('trip-planer-slider', this);
        this.$slickSlider = $ele.find('.js-trip-planer-slider__slick-slider');
        this.removedIds = []; // already added slides that are hidden by slick
        if ($ele.data('ignored-ids')) {
            this.ignoredIds = $ele.data('ignored-ids').replace(' ', '').split(','); // ids of slides that should be ignored eg. id of tip teaser
        }
        this.addedSlidesId = []; /*list of all slides id in the slider, including slides that are hidden*/

        if (this.$ele.data('type-filter')) {
            this.filter = this.$ele.data('type-filter');
        }
        if (this.$ele.data('ignored-type')) {
            /*inverted filter*/
            this.ignoredType = this.$ele.data('ignored-type');
        }

        //use matchMedia to change options cause slick slider responsive option destroys slider items mobile
        this.$slickSlider.slick({
            dots: true, /*should always be true; hide with css (accessibility)*/
            dotsClass: matchMedia('(max-width: 767px)').matches ? 'slick-dots mt-4' : 'sr-only',
            slidesToShow: matchMedia('(max-width: 767px)').matches ? 1.1 : 3,
            slidesToScroll: 1,
            arrows: matchMedia('(max-width: 767px)').matches ? false : true,
            prevArrow:"<button type='button' class='slick-prev text-theme' aria-label='prev'></button>",
            nextArrow:"<button type='button' class='slick-next text-theme' aria-label='next'></button>",
            infinite: false,
            centerPadding: '20px'
        });
        this.slickSlider = this.$slickSlider.slick('getSlick');

        this.$ele.find('.js-trip-planer-slider__show-if-empty').each(function () {
            $(this).closest('.slick-slide').addClass('show-if-empty');
        });
    }

    TripPlanerSlider.prototype.addSlides = function (data) {

        //console.log('slider add slides');
        let self = this;

        $.each(data, function (index, item) {
            if ((self.filter && self.filter !== item.type)
                || (self.ignoredType && self.ignoredType === item.type)) {
                /*do not add because of filter*/
                return;
            }

            if (self.addedSlidesId.map(function (x) {
                return x.toString().toLowerCase();
            }).indexOf(item.objectid.toString().toLowerCase()) < 0) {
                self.addedSlidesId.push(item.objectid)
            }

            let removeIndex = self.removedIds.map(function (x) {
                return x.toString().toLowerCase();
            }).indexOf(item.objectid.toString().toLowerCase());

            if (removeIndex > -1) {
                /*if slide is already in sliders */
                self.removedIds.splice(removeIndex ,1);
                self.updateFilter();
            } else {
                if (self.ignoredIds && self.ignoredIds.map(function (x) {
                    return x.toString().toLowerCase();
                }).indexOf(item.objectid.toString().toUpperCase()) > -1) {
                    /*do not add ignoredIds*/
                    return;
                }

                let slide = render(<Teaser {...item}></Teaser>);
                self.$slickSlider.slick('slickUnfilter');

                self.$slickSlider.slick('slickAdd', slide);

                self.updateFilter();
                initModulesInScope(self.$slickSlider);
            }
        });
    };

    TripPlanerSlider.prototype.removeSlide = function (id) {

        console.log('prototype remove slides');
        if (this.removedIds.indexOf(id) < 0 && this.addedSlidesId.indexOf(id) > -1) {
            this.removedIds.push(id);
        }

        this.updateFilter();
    };

    TripPlanerSlider.prototype.updateFilter = function () {
        let removedSlideSelector = '';
        for (let i = 0; i < this.removedIds.length; i++) {
            if (i !== 0) {
                removedSlideSelector += ', '
            }
            removedSlideSelector += '[data-slide-id="' + this.removedIds[i] + '"]';
        }
        this.$slickSlider.find(removedSlideSelector).closest('.slick-slide').addClass('is-filtered');

        if (this.addedSlidesId.length - this.removedIds.length > 0) {
            this.$slickSlider.slick('slickFilter', ':not(.show-if-empty, .is-filtered)');
        } else {
            this.$slickSlider.slick('slickFilter', ':not(.is-filtered)');
        }

        /*reset the state of tripPlanerAdd buttons manually because slick carousel is doing some dom manipulations
        that cause problems with the right state */
        app.modules.tripPlanerAdd(this.$ele, true);
    };

    let sliderObjs = [];
    let prevTripPlanerItems =[];

    let isInitialized = false;
    function init() {
        if (isInitialized) {
            return;
        }

        isInitialized = true;

        /*initial data*/
        updateSliders(tripplanerItemsStore.getItemsWithDetail());

        subscribers.subscribe('data-tripPlanerItemsDetail-change', function () {
            updateSliders(tripplanerItemsStore.getItemsWithDetail());
        });
    }

    function updateSliders (items) {
        //items to remove
        $.each(prevTripPlanerItems, function (index, item) {
            if (!tripplanerItemsStore.getItemWithDetail(item.objectid, item.type)) {
                $.each(sliderObjs, function (index, sliderObj) {
                    sliderObj.removeSlide(item.objectid, item.type);
                });
            }
        });

        //items to add
        let newTripPlanerItems = tripplanerItemsStore.getItemsWithDetail();

        $.each(newTripPlanerItems, function (index, item) {
            let isInPrevList = false;

            $.each(prevTripPlanerItems, function (index, prevItem) {
                if (prevItem.objectid.toString().toLowerCase() === item.objectid.toString().toLowerCase()
                    && prevItem.type === item.type) {
                    isInPrevList = true;
                }
            });

            if (!isInPrevList) {
                $.each(sliderObjs, function (index, sliderObj) {
                    sliderObj.addSlides([item]);
                });
            }
        });

        /*copy array*/
        prevTripPlanerItems = items.slice();
    }

    let $slider = $scope.find('.js-trip-planer-slider');
    import('slick-carousel').then(function () {
        $slider.each(function () {
            sliderObjs.push(new TripPlanerSlider($(this)));
        });
    });

    import('slick-carousel').then(function () {
        init();
    });
}