"use strict";

import * as slider from "./slider";
import * as lazyImg from "@elements/lazy-img";

export function initInScope($scope) {
    import('slick-carousel').then(function () {
        $scope.find('.js-select__svg-region').on('click', function () {
            if($('.js-slider--region-weather').length) {
                /*variante 1: goto slide*/
                let $slideNr = $(this).data('regionslide-nr');
                $('.js-slider--region').slick('slickGoTo', $slideNr);
            } else {
                /*variante 2: goto link*/
                window.location.href=$(this).data('href');
            }
        });

        $scope.find('.js-hover__svg-region').on('mouseenter', function () {
            if($('.js-slider--region').length) {
                let $slideNr = $(this).data('regionslide-nr');
                $('.js-slider--region').slick('slickGoTo', $slideNr);
            }
        });

        $scope.find('.js-slider--region').on('afterChange', function(event, slick, currentSlide, nextSlide){
            /* active map */
            let $activeSlide = $('.slick-slide.slick-current .js-map-select__select').attr('data-region');
            let $activeSlideDolomiten = $('.slick-slide.slick-current .js-map-select__select').attr('data-area');

            let $svgRegion = $(this).closest('.js-map-select').find('[data-region="' + $activeSlide + '"]');
            let $svgRegionDolomiten = $(this).closest('.js-map-select').find('[data-area="' + $activeSlideDolomiten + '"]');

            $scope.find('.js-select__svg-region, .js-hover__svg-region').removeClass('is-hovered');

            if ($svgRegion && $svgRegion.length) {
                $svgRegion.addClass('is-hovered');
                $svgRegionDolomiten.addClass('is-hovered');
            }
            /* ajax weather content */
            if($('.js-slider--region-weather').length) {
                window.setTimeout(
                    function(){

                        let $content = $scope.find('.js-weather-reload__result');
                        let url = $('.slick-slide.slick-current .js-map-select__select').attr('data-weather-ajax');
                        let region = $('.slick-slide.slick-current .js-map-select__select').attr('data-region');
                        let regionUrl = url+'?region='+region;
                        let $loading = $scope.find('.js-weather-reload__loading');

                        $loading.removeAttr('hidden');

                        let pendingRequest = null;

                        if (pendingRequest) {
                            pendingRequest.abort();
                            pendingRequest = null;
                        }

                        pendingRequest = fetch(regionUrl, {
                            method: 'GET',
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                        })
                            .then(response => response.text())
                            .then(function (result) {
                                $content.html(result);
                                $loading.attr('hidden', 'hidden');
                                //initModulesInScope($content);
                                slider.initInScope($content);
                                lazyImg.initInScope($content);

                                $loading.attr('hidden', 'hidden');
                                pendingRequest = null;
                            }).catch(function (jqXHR, textStatus, errorThrown) {
                                if (textStatus !== 'abort') {
                                    $loading.attr('hidden', 'hidden');
                                    console.error(textStatus, errorThrown, jqXHR);
                                }
                                $loading.attr('hidden', 'hidden');
                                pendingRequest = null;
                            });

                        /* click on map */
                        $scope.find('.js-hover__svg-region').on('click', function () {
                            window.location.href=$(this).data('href');
                        });
                    }, 750);
            } else {
                /* click on map */
                $scope.find('.js-hover__svg-region').on('click', function () {
                    let navLink = $(this).closest('.js-map-select').find('.slick-current a').attr('href');
                    window.location.href = navLink;
                });
            }
        });
    });
}