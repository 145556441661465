"use strict";

import {loadTooltipBundle} from "./tooltipBundle";

let items = JSON.parse(sessionStorage.getItem('multiple-inquiry')) || [],
    $inquiryBtn = $('.js-multiple-inquiry-btn'),
    $inquiryCount = $('.js-multiple-inquiry-btn__count'),
    btnUrl = $inquiryBtn.attr('href');

// if(items.length) {
//     let url = btnUrl + (btnUrl.indexOf('?') < 0 ? '?': '&') + 'inquiryHotels=' + getParams(items);
//     $inquiryBtn.attr('href', url);
// }

export function initInScope($scope) {
    let $checkboxes = $scope.find('.js-multiple-inquiry-add');

    function toggleInquiryBtn() {
        if($inquiryBtn.length) {
            if(items.length) {
                $inquiryBtn.addClass('is-active');
                $inquiryCount.html(items.length);
            } else {
                $inquiryBtn.removeClass('is-active');
            }
            loadTooltipBundle().then(() => {
                if(items.length == 10) {
                    let $toolTipError = $('.js-multiple-inquiry-btn__tooltip');
                    let $toolTipErrorTitle = $toolTipError.attr("data-error-title");

                    $toolTipError.attr("data-original-title", $toolTipErrorTitle);
                    $toolTipError.tooltip("show");
                    window.setTimeout(
                        function(){
                            $toolTipError.tooltip("hide");
                        }, 2500);
                } else {
                    $('.js-multiple-inquiry-btn__tooltip').tooltip("hide");
                }
            });

        }
    }


    function checkboxDisable() {
        if(items.length == 10) {
            $('.js-multiple-inquiry-add').prop('disabled',true);
            $('.js-multiple-inquiry-add:checked').prop('disabled',false);
        } else {
            $('.js-multiple-inquiry-add').prop('disabled',false);
        }
    }


    toggleInquiryBtn();
    checkboxDisable();

    if($inquiryBtn.length) {
        let url = btnUrl + (btnUrl.indexOf('?') < 0 ? '?' : '&') + getParams(items);
        $inquiryBtn.attr('href', url);
    }

    //checked checkboxes
    $checkboxes.each(function () {
        for (let i = 0; i < items.length; i++) {
            if(items[i].objectid === $(this).val()) {
                $(this).prop('checked', true);
                $(this).prop('disabled', false);
            }
        }
    });

    //add/remove items
    $checkboxes.on('change', function (e) {

        let $this = $(this);

        if($this.is(':checked')) {
            let newItem = {
                objectid: $this.val()
            };

            items.push(newItem);
            e.stopImmediatePropagation();
        } else {
            items = items.filter(function (item) {
                return item.objectid !== $this.val()
            });
        }

        toggleInquiryBtn();
        checkboxDisable();

        sessionStorage.setItem('multiple-inquiry', JSON.stringify(items));

        let url = btnUrl + (btnUrl.indexOf('?') < 0 ? '?': '&') + getParams(items);
        $inquiryBtn.attr('href', url);

    });
}

function getParams (items) {
    let params;
    let test = [];
    for (let i = 0; i < items.length; i++) {
        test.push(items[i].objectid);
    }
    params = test.join(',');

    return params;

}