// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';



let _config = window['_config'] || {};


let app = window.app || {};
window.app = app;
window.app.modules =  app.modules || {};



/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import './shared/common';

app.modules.popover = function () {
    import('bootstrap/js/dist/popover');
}

import * as paymentDataForm from './scripts/paymentDataForm';
app.modules.paymentDataForm = paymentDataForm.initInScope;

import * as youtubeIframe from './scripts/youtubeIframe';
app.modules.youtubeIframe = youtubeIframe.initInScope;

import * as trustYou from './scripts/trustYou';
app.modules.trustYou = trustYou.initInScope;

import * as lightbox from './scripts/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as metaCheckbox from './scripts/meta-checkbox';
app.modules.metaCheckbox = metaCheckbox.initInScope;

import * as checkboxToggler from './scripts/checkboxToggler';
app.modules.checkboxToggler = checkboxToggler.initInScope;

import * as datePicker from './scripts/datepicker';
app.modules.datePicker = datePicker.initInScope;

import * as datepickerActiveDates from './scripts/datepickerActiveDates';
app.modules.datepickerActiveDates = datepickerActiveDates.initInScope;

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

// import * as expandableTeaser from './scripts/expandableTeaser';
// app.modules.expandableTeaser = expandableTeaser.initInScope;


import * as svgMapSlider from './scripts/svgMapSlider';
app.modules.svgMapSlider = svgMapSlider.initInScope;

// import * as warningPopup from './scripts/warningPopup';
// app.modules.warningPopup = warningPopup.initInScope;

import * as map from './scripts/map';
app.modules.map = map.initInScope;

import * as mapOverlay from './scripts/mapOverlay';
app.modules.mapOverlay = mapOverlay.initInScope;
import * as rome2rioForm from './scripts/rome2rioForm';
app.modules.rome2rioForm = rome2rioForm.initInScope;

import * as tripPlanerMap from './scripts/tripplaner/tripplaner-map';
app.modules.tripPlanerMap = tripPlanerMap.initInScope;

import * as tripPlanerList from './scripts/tripplaner/tripplaner-list';
app.tripPlanerList = tripPlanerList.init;

import * as tripPlanerSlider from './scripts/tripplaner/tripplaner-slider';
app.modules.tripPlanerSlider = tripPlanerSlider.initInScope;

import * as filterDropdown from './scripts/filter-dropdown';
app.modules.filterDropdown = filterDropdown.initInScope;

import * as mapListResult from './scripts/mapListResult';
app.modules.mapListResult = mapListResult.initInScope;

import * as rangeSlider from './scripts/rangeSlider';
app.modules.rangeSlider= rangeSlider.initInScope;

import * as roomForm from './scripts/room-form';
app.modules.roomForm = roomForm.initInScope;

import * as multipleInquiry from './scripts/multiple-inquiry';
app.modules.multipleInquiry = multipleInquiry.initInScope;

import * as roomSelectionBar from './scripts/roomSelectionBar';
app.modules.roomSelectionBar = roomSelectionBar.initInScope;

import * as roomSection from './scripts/roomSection';
app.modules.roomSection = roomSection.initInScope;

import * as scrollAnimate from './scripts/scrollAnimate';
app.modules.scrollAnimate = scrollAnimate.initInScope;

import * as quickFinderLoadingOverlay from './scripts/quickFinderLoadingOverlay';
app.modules.quickFinderLoadingOverlay = quickFinderLoadingOverlay.initInScope;

/* only used on search page*/
import * as searchForm from './scripts/searchForm';
app.modules.searchForm = searchForm.initInScope;

// import * as collapseOpen from './scripts/collapseOpen';
// app.modules.collapseOpen = collapseOpen.initInScope;

app.multipleInquiryRemove = function () {
    sessionStorage.removeItem('multiple-inquiry');
};

app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });

    import('./scripts/filter-form/quick-filter').then(function (quickFilter) {
        app.modules.filterFormquickFilter = quickFilter.initInScope;
        quickFilter.initInScope($('body'));
    });
};

import * as lazyIframe from '@elements/lazy-iframe';
app.modules.lazyIframe = lazyIframe.initInScope;

import * as wallsIo from './scripts/wallsIo';
app.wallsIo = wallsIo.init;

import * as numberSpinner from '@elements/number-spinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as hideStickyBottomBarOnScroll from './scripts/hideStickyBottomBarOnScroll';
app.hideStickyBottomBarOnScroll = hideStickyBottomBarOnScroll.init;

import initModulesInScope from "./scripts/initModulesInScope";
import {onPolyfillsReady} from "js/scripts/polyfills";

onPolyfillsReady(function () {
    (function( $ ){
        "use strict";

        /* -> _config._preload = Load this functions first */
        if (_config['_preload']) {
            $.each( _config['_preload'], function( _key, _val ){
                if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                    window.app[_key]();
                }
            });
        }

        /* -> _config = Load all others (not _preload and _reload) */
        $.each( _config, function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
                window.app[_key]();
            }
        });

        /* -> _config._reload = Load the ajaxInclued and others after the rest */
        if (_config['_reload']) {
            $.each( _config['_reload'], function( _key, _val ){
                if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                    window.app[_key]();
                }
            });
        }

        initModulesInScope();
    })(jQuery);
});