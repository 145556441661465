"use strict";

import loadJQueryUi from '@elements/load-jquery-ui';
import {loadTooltipBundle} from "./tooltipBundle";

$.fn.zIndex = function() {
    /* backfill old zIndex function for old jquery ui datepicker*/
    return this.css("zIndex");
};

export function initInScope ($scope) {

    Promise.all([
        loadTooltipBundle(),
        loadJQueryUi()
    ]).then(function () {
        let defaultOptions = {
            minDate: 0,
            showAnim: 'show'
        };

        let datePicker = $scope.find('.js-datepicker-active-dates');

        datePicker.each(function () {
            let $datePickerInput = $(this);
            let datePickerOptions = $.extend({}, defaultOptions, {
                minDate: new Date(new Date().toDateString()), /*current date; new Date() returns current time*/
                maxDate: "+1Y"
            });

            if ($datePickerInput.data('mindate')) {
                datePickerOptions.minDate = new Date($datePickerInput.data('mindate'));
            }
            if ($datePickerInput.data('maxdate')) {
                datePickerOptions.maxDate = new Date($datePickerInput.data('maxdate'));
            }

            let highlightedDates = eval($datePickerInput.data('highlight-dates'));
            $.each(highlightedDates, function(index, highlightedDay) {
                highlightedDay.date = new Date(highlightedDay.date);
                if (highlightedDay.tooltipElementId) {
                    let $tooltip = $('#' + highlightedDay.tooltipElementId);
                    if ($tooltip && $tooltip.length) {
                        highlightedDay.tooltip = $tooltip.html();
                    }
                }
            });

            $datePickerInput.data('highlight-dates', highlightedDates);

            $datePickerInput.on('mouseenter', '.ui-state-highlight', function () {
                let $this = $(this);
                let $element = $(this).find('a, span');

                $element.tooltip({
                    html: true,
                    title: $this.attr('title'),
                    trigger: 'manual',
                    placement: 'top',
                    container: '.datepicker-active-dates'
                }).tooltip('show');
            }).on('mouseleave', '.ui-state-highlight', function () {
                $(this).find('a, span').tooltip('hide');
            });

            datePickerOptions.beforeShowDay = function (date) {
                let highlightedDates = $datePickerInput.data('highlight-dates');
                if (highlightedDates) {
                    let matchedHighlightDays = $.grep(highlightedDates, function (highlightedDate) {
                        return highlightedDate.date.toDateString() == date.toDateString();
                    });

                    return matchedHighlightDays.length ? [true, 'ui-state-highlight', matchedHighlightDays[0].tooltip]: [false, ''];
                }

                return [true, ''];
            };

            $datePickerInput.datepicker(datePickerOptions);

        });

    });

}