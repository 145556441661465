"use strict";

export function initInScope($scope) {
    $scope.find('.js-checkbox-toggler').on('change', function () {
        let $subCheckBoxes = $(this).closest('.js-checkbox-toggler__group').find('.js-checkbox-toggler__sub-group');

        if ($(this).prop('checked')) {
            $subCheckBoxes.removeAttr('hidden');
        } else {
            $subCheckBoxes.attr('hidden','hidden');
        }
    });


    $scope.find('.js-checkbox-toggler__sub-group input').on('change', function () {
        if ($('.js-checkbox-toggler__sub-group input:checked').length) {
        } else {
            $(this).closest('.js-checkbox-toggler__group').find('.js-checkbox-toggler__sub-group').attr('hidden','hidden');
        }

    });
}