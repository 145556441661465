export function initInScope ($scope) {
    $scope.find('.js-youtube__btn').on('click', function (evt) {
        evt.preventDefault();

        let $ytVideo = $(this).closest('.js-youtube').find('iframe');
        let $ytVideoSrc = $ytVideo.attr('src');
        let $ytVideoWrapper = $(this).closest('.js-youtube').find('.js-youtube__iframe');

        $ytVideoWrapper.removeAttr('hidden');
        $ytVideo.attr('src', $ytVideoSrc + '&autoplay=1');

        $(this).remove();
    });
};