"use strict";

export function initInScope($scope) {
    $scope.find('.js-quick-finder').each(function () {
        var $loadingOverlay = $('.js-quick-finder__loading');
        var $form = $(this);

        $form.on('submit', function () {
           $loadingOverlay.removeAttr('hidden');
        });
    });
}