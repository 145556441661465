"use strict";

import loadScript from "@elements/load-script";

export function initInScope($scope) {
    let lightbox = $scope.find('.js-lightbox'),
        lightboxItems = $scope.find('.js-lightbox__item'),
        dataLightboxVideo = false,
        dataLightboxDownload = false;
    import('lightgallery').then(function () {
        lightbox.each(function (key, value) {
            dataLightboxVideo = $(this).data('lightbox-video');
            dataLightboxDownload = $(this).data('lightbox-download');

            if (dataLightboxVideo !== undefined && dataLightboxVideo === true) {

                /* the following imports are only needed if there are videos in your lightGallery*/
                /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
                /* IMPORTANT: The controls attribute must be set on the <video>*/
                /* IMPORTANT: class "js-video-js" is not necessary for videos in the lightgallery (own molecule) */

                // import('video.js').then(function (videojs) {
                //     /* because lg-video needs a global instance of videojs */
                //     window.videojs = videojs.default;
                // });

                loadScript('//f.vimeocdn.com/js/froogaloop2.min.js');
                import('lg-video');
            }

            $(this).lightGallery({
                selector: '.js-lightbox__item',
                animateThumb: false,
                download: dataLightboxDownload || false,
                videojs: dataLightboxVideo || false,
                counter: false
            });
        });


        //prevent isClickable
        lightboxItems.on('click', function (evt) {
            evt.stopImmediatePropagation();
        });
    });
}
