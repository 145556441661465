"use strict";

export function initInScope($scope) {
    let $target = $scope.find('.js-scroll-animate');

    if ($target && $target.length) {
        let targetHeight = $target[0].offsetHeight,
            currentScrollTop = $(window).scrollTop(),
            scaling = 1.0,
            navigator = window.navigator.userAgent;

        if(!$target.height()) $target.height(targetHeight);

        // disable scroll animations in IE due to performance issues
        if(navigator.indexOf('Trident') === -1) {
            $(window).scroll(function () {

                if ((currentScrollTop > 15) && (currentScrollTop < $(window).height())) {

                    // if target element is in slider
                    if ($target.closest('.slick-slide')) {
                        $target = $scope.find('.slick-current.slick-active').find('.js-scroll-animate');

                        // reset transformation on slick slide
                        $scope.find('.slider').on('beforeChange', function () {
                            $target.css('transition', 'none');
                            $target.css('transform', 'scale(1)');
                            //scaling = 1.0;
                        });
                    }
                    $target.css('transition', 'transform 2.550s ease');

                    if ($(window).scrollTop() > currentScrollTop) {
                        //scaling = Math.round(scaling * 1000) / 1000 + 0.0035;
                        $target.css('transform', 'scale(1.15)');
                    }
                    else {
                        //if(Math.round(scaling * 1000) / 1000 > 1.000) scaling = Math.round(scaling * 1000) / 1000 - 0.0035;
                        $target.css('transform', 'scale(1)');
                    }
                }
                if (currentScrollTop <= 15) {
                    $target.css('transition', 'transform 1.550s ease');
                    $target.css('transform', 'scale(1)');
                    //scaling = 1.0;
                }
                currentScrollTop = $(window).scrollTop();
            });
        }
        else {
            console.warn('Scroll animations are disabled in IE.');
        }
    }


}