/** @jsx h */

"use strict";

import * as tripplanerItemsStore from './tripplaner-items-store';
import * as subscribers from '../subscribe';
import initModulesInScope from '../initModulesInScope';
import { h, render } from 'preact';
import Teaser from './tripplaner-teaser';

export function init() {
    $('.js-trip-planer-list').each(function () {
        let $list = $(this);

        let $row = $list.find('.js-trip-planer-list__placeholder');
        let prevTripPlanerItems = [];

        function addItem(item) {
            let $teaser = render(<Teaser {...item}></Teaser>);
            $row.prepend($teaser);

            initModulesInScope($list);
        }

        function removeItem(objectid, type) {
            $list.find('.js-trip-planer-list__item[data-id="' + objectid.toString().toUpperCase() + '"], .js-trip-planer-list__item[data-id="' + objectid.toString().toLowerCase() + '"]').remove();
        }

        function updateList () {

            //console.log('update list');
            let newTripPlanerItems = tripplanerItemsStore.getItemsWithDetail();

            //items to remove
            $.each(prevTripPlanerItems, function (index, item) {
                if (!tripplanerItemsStore.getItemWithDetail(item.objectid, item.type)) {
                    removeItem(item.objectid, item.type);
                }
            });

            //console.log('add items start');
            //items to add
            $.each(newTripPlanerItems, function (index, item) {
                let isInPrevList = false;
                console.log($list.data('type-ignored'));

                if ($list.data('type-ignored') && $list.data('type-ignored').split(',').indexOf(item.type) < 0) {
                    $.each(prevTripPlanerItems, function (index, prevItem) {
                        if (prevItem.objectid.toString().toLowerCase() === item.objectid.toString().toLowerCase()
                            && prevItem.type === item.type) {
                            isInPrevList = true;
                        }
                    });

                    if (!isInPrevList) {
                        addItem(item);
                    }
                } else if ($list.data('type-filter') == item.type) {
                    $.each(prevTripPlanerItems, function (index, prevItem) {
                        if (prevItem.objectid.toString().toLowerCase() === item.objectid.toString().toLowerCase()
                            && prevItem.type === item.type) {
                            isInPrevList = true;
                        }
                    });

                    if ($list.data('type-filter') == 'extern') {
                        console.log('is extern');
                        $('.js-tripplaner-list-extern').attr('hidden',false);
                    } else {
                        //console.log('not extern');
                    }


                    if (!isInPrevList) {
                        addItem(item);
                    }
                }
            });

            /*copy array*/
            prevTripPlanerItems = newTripPlanerItems.slice();
        }

        subscribers.subscribe('data-tripPlanerItemsDetail-change', function () {
            updateList();
        });

        updateList();

        }

    )



}