export function initInScope($scope) {
    import('./microTemplating').then(function(microTemplating) {
        $scope.find('.js-room-selection-bar').each(function () {
            var $affixBar = $(this);

            var isAffix = true;
            var isOpen = false;

            var $placeholder = $affixBar.closest('.js-room-selection-bar__placeholder');
            if (!$placeholder || !$placeholder.length) {
                console.warn('Could not initialize app.roomSelectionBar. No js-room-selection-bar__placeholdesr element was found for ', this);
                return;
            }

            var $detail = $affixBar.find('.js-room-selection-bar__detail');

            var moverOffset = $detail.outerHeight();
            console.info('moverOffset', moverOffset);
            var $mover = $affixBar.find('.js-room-selection-bar__mover');


            $placeholder.css('min-height', $affixBar.outerHeight() + 'px');



            var $roomList = $affixBar.find('.js-room-selection-bar__room-list');
            var $roomCount = $affixBar.find('.js-room-selection-bar__room-count');
            var $personCount = $affixBar.find('.js-room-selection-bar__person-count');

            $('.js-room-section').on('ui-room-selection-changed', updateRoomInfo);

            $('.js-room-section__active-toggle-checkbox').on('change', updateInitiallyHidden);

            function updateInitiallyHidden() {
                var initiallyHidden = $affixBar.find('.js-room-selection-bar__initially-hidden');
                if (initiallyHidden) {
                    if (initiallyHidden.hasClass('hidden')) {
                        initiallyHidden.removeClass('hidden');
                    } else {
                        initiallyHidden.addClass('hidden');
                    }
                }
            }

            function updateRoomInfo() {
                var selectedRooms = $(this).data('selected-rooms');
                var selectedBoarding = $(this).data('selected-boarding');

                var allRoomSelections = [];
                selectedRooms.forEach(function (room) {
                    Array.prototype.push.apply(allRoomSelections, room.selections);
                });

                var totalRoomCount = allRoomSelections.length;
                var totalPersonCount = allRoomSelections.map(function (selection) {
                    return selection.adults + selection.children;
                }).reduce(function (a, b) {
                    return a + b;
                }, 0);

                $roomCount.text(totalRoomCount);
                $personCount.text(totalPersonCount);

                var newRoomListMarkup = microTemplating.tmpl($('#js-room-selection-bar__room-list-template').html(), {
                    rooms: selectedRooms
                });

                var totalPrice = 0;
                for (var i = 0; i < selectedRooms.length; i++) {
                    for (var j = 0; j < selectedRooms[i].selections.length; j++) {
                        totalPrice += selectedRooms[i].selections[j].price;
                    }
                }

                $('.js-room-selection-bar__total-price').text(
                    totalPrice.toLocaleString(_config.lang, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                );

                if (selectedBoarding && selectedBoarding.text) {
                    $('.js-room-selection-bar__boarding-text').text(selectedBoarding.text);
                }


                $roomList.empty().append(newRoomListMarkup);

                moverOffset = $detail.outerHeight();
                $placeholder.css('min-height', $affixBar.outerHeight() + 'px');
            }
        });
    });
}
