export function initInScope($scope) {
    var $roomSelects = $scope.find('.js-room-section__room-select');
    var $container = $scope.find('.js-room-section');
    var $radios = $scope.find('.js-room-section__boarding-radio');
    var $allCheckboxes = $scope.find('.js-room-section__active-toggle-checkbox');

    var fnParseRoomSelection = function (roomElems) {
        var result = [];

        roomElems.each(function (index, room) {
            var $selectedCheckboxes = $(room).find('.js-room-section__room-select input:checked');
            var roomId = $(room).data('room-id');

            if ($selectedCheckboxes && $selectedCheckboxes.length) {
                var newRoom = {
                    id: roomId,
                    name: $(room).data('room-name'),
                    selections: []
                };

                var selectedPricing = $radios.filter(':checked').val();

                result.push(newRoom);
                $selectedCheckboxes.each(function (index, checkbox) {
                    var $select = $(checkbox).closest('.js-room-section__room-select');
                    newRoom.selections.push({
                        roomNr: $select.data('room-number'),
                        adults: $select.data('room-adults'),
                        children: $select.data('room-children'),
                        price: $select.data('room-price-' + selectedPricing)
                    });
                });
            }
        });

        return result;
    };

    $roomSelects.on('change', function () {
        if ($(this).find('input[type="checkbox"]').is(':checked')) {
            var roomNumber = $(this).data('room-number');
            $roomSelects
                .filter('[data-room-number="' + roomNumber + '"]')
                .not($(this))
                .find('input[type="checkbox"]:checked')
                .prop('checked', false)
                .trigger('change');
        }

        var $container = $(this).closest('.js-room-section');
        var $rooms = $container.find('.js-room-section__room');
        var result = fnParseRoomSelection($rooms);

        $container.data('selected-rooms', result);
        $container.trigger('ui-room-selection-changed');

        var $activeRoom = $(this).closest('.js-room-section__room');
        var availableRoomsCount = $activeRoom.data('rooms-available');
        var $checkboxes = $activeRoom.find('.js-room-section__room-select input[type="checkbox"]');
        var selectedRoomsCount = $checkboxes.filter(':checked').length;

        if (selectedRoomsCount >= availableRoomsCount) {
            $checkboxes.not(':checked').attr('disabled', 'disabled');
        } else {
            $checkboxes.attr('disabled', null);
        }
    });

    $roomSelects.trigger('change');

    $radios.on('change', function () {
        var $container = $(this).closest('.js-room-section');
        var $rooms = $container.find('.js-room-section__room');
        var $selectedRadio = $radios.filter(':checked');
        var result = fnParseRoomSelection($rooms);

        $container.data('selected-rooms', result);

        $container.data('selected-boarding', {
            value: $selectedRadio.val(),
            text: $selectedRadio.closest('label').text()
        });
        $container.trigger('ui-room-selection-changed');

        updatePrices($container);
    }).trigger('change');

    if ($allCheckboxes) {
        $allCheckboxes.each(function (index, checkbox) {
            var $room = $(checkbox).closest('.js-room-section__room');
            $(this).on('change', function () {
                $('.js-room-section__room').each(function (){
                    var roomBtn = $(this).find('.js-btn-toggle');
                    roomBtn.toggleClass('custom-checkbox__btn');
                })
                $room.toggleClass('teaser-wide--has-border');
                $room.find('.js-btn-toggle').toggleClass('custom-checkbox__btn');
            });
        });
    }

    function updatePrices($container) {
        $container.find('.js-room-section__room-select').each(function () {
            $(this).find('.js_room-select-item-price').text(
                $(this).data('room-price-' + $radios.filter(':checked').val())
                    .toLocaleString(_config.lang, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
            );
        });
    }
}

