/** @jsx h */

import { h, render } from 'preact';

export default function (props) {

    let detailLink = _config.detailTranslation;

    return (
        <div>
            <h3 class="h4 mb-2"><span dangerouslySetInnerHTML={{__html: props.title}}></span></h3>
            <div className="fs1 mb-2" dangerouslySetInnerHTML={{__html: props.description}}></div>
            <div className="">
                <a className="text-primary fs1 strong" href={props.url}>{detailLink}</a>
            </div>
        </div>
    );
}