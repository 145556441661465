/** @jsx h */

"use strict";

import * as tripplanerItemsStore from './tripplaner-items-store';
import * as subscribers from '../subscribe';
import { h, render } from 'preact';
import Infobox from './tripplaner-infobox';

export function initInScope($scope) {
    let maps = [];
    let prevTripPlanerItems = [];

    function addMapMarker(item) {
        //console.log(item);
        if(item.lat && item.lon) {
            let marker = {
                id: item.objectid,
                lat: item.lat,
                lon: item.lon,
                infowindow: render(<Infobox {...item}></Infobox>)
            };

            $.each(maps, function (i, $map) {
                let mapObj = $map.data('map');

                if (mapObj && mapObj.initialized) {
                    mapObj.setMarker(marker, true);
                } else {
                    $map.on('initialized', function initializedHandler() {
                        $map.data('map').setMarker(marker, true);
                        $map.off('initialized', initializedHandler);
                    });
                }
            });
        }
    }

    function removeMapMarker(id, type) {
        $.each(maps, function (i, $map) {
            let mapObj = $map.data('map');
            if (mapObj && mapObj.initialized) {
                mapObj.removeMarkers(id);
            } else {
                $map.on('initialized', function initializedHandler() {
                    $map.data('map').removeMarkers(id);
                    $map.off('initialized', initializedHandler);
                });
            }
        });
    }

    function updateMapMarker() {
        let newTripPlanerItems = tripplanerItemsStore.getItemsWithDetail();

        //items to remove
        $.each(prevTripPlanerItems, function (index, item) {
            if(item.lat && item.lon) {
                if (!tripplanerItemsStore.getItemWithDetail(item.objectid, item.type)) {
                    removeMapMarker(item.objectid, item.type);
                }
            }
        });

        //items to add
        $.each(newTripPlanerItems, function (index, item) {
            let isInPrevList = false;

            $.each(prevTripPlanerItems, function (index, prevItem) {
                if (prevItem.objectid.toString().toLowerCase() === item.objectid.toString().toLowerCase()
                    && prevItem.type === item.type) {
                    isInPrevList = true;
                }
            });

            if (!isInPrevList) {
                addMapMarker(item);
            }
        });

        /*copy array*/
        prevTripPlanerItems = newTripPlanerItems.slice();
    }

    let isInitialized = false;
    function init() {
        if (isInitialized) {
            return;
        }

        isInitialized = true;

        subscribers.subscribe('data-tripPlanerItemsDetail-change', function () {
            updateMapMarker();
        });

        updateMapMarker();
    }

    $scope.find('.js-trip-planer-map').each(function () {
        maps.push($(this));
    });

    init();
}