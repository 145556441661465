/** @jsx h */

import { h, render } from 'preact';

export default function (props) {
    return (
        <div className="col-6 col-md-3 js-trip-planer-list__item" data-id={props.objectid}>
            <section className="teaser isClickable">
                <div className="embed-responsive embed-responsive-3by2">
                    <img className="embed-responsive-item teaser__img" srcSet={props.imgUrl} />
                    <div className="teaser__body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h3 className="teaser__title">
                                    <a href={props.url} title={props.titleString}>{props.title}</a>
                                </h3>
                            </div>
                            <div className="align-self-end text-right">
                                    <span className="teaser__tripplaner">
                                        <a href="javascript://" className="trip-planer-add js-trip-planer-add"
                                           data-id={props.objectid} data-typ={props.type}>
                                            <span className="trip-planer-add__visible-added">
                                                <span className="icon trip-planer__brandicon icon-brand-form"></span>
                                                <span className="trip-planer-add__icon icon icon-heart-filled"></span>
                                            </span>
                                            <span className="trip-planer-add__hidden-added">
                                                <span className="icon trip-planer__brandicon icon-brand-form"></span>
                                                <span className="trip-planer-add__icon icon icon-heart"></span>
                                            </span>
                                        </a>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
);
}