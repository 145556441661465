import * as mapInit from "./map";
import * as lazyImgInit from "@elements/lazy-img";

export function initInScope($scope) {
    let $mapToggle = $scope.find('.js-map-toggle');
    let $listToggle = $scope.find('.js-list-toggle');
    let $resultVersion = $scope.find('.js-result-version');
    let documentId = $('.js-map-toggle').attr('data-document-id');
    let poisUrl = $('.js-map-result').attr('data-ajax-pois');



    function loadMapPois () {
        let filterUrlParams = location.search;

        if(filterUrlParams) {
            //console.log(filterUrlParams);
        } else {
            filterUrlParams = "?documentId="+documentId;
            //console.log(filterUrlParams);
        }

        let $mapResult = $('.js-map-result');

        let pendingRequest = null;

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        pendingRequest = fetch(poisUrl + filterUrlParams, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then(response => response.text())
            .then(function (result) {
                $mapResult.html(result);
                mapInit.initInScope($mapResult);
                pendingRequest = null;
            }).catch(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    console.error(textStatus, errorThrown, jqXHR);
                }
                pendingRequest = null;
            });
    }



    /* load map pois initial */
    if($('.js-map-result').hasClass('js-map-result--visible')) {
        loadMapPois ();
    }



    /* load map pois on click */
    $mapToggle.click(function () {
        let $mapResult = $('.js-map-result');
        let $listResult = $('.js-list-result');
        let $filterButton = $('.js-filter-dropdown__toggle--price-sort');


        $(this).attr('hidden','hidden');
        $resultVersion.val('map');

        $mapResult.attr('hidden',false);
        $listResult.attr('hidden','hidden');
        $listToggle.attr('hidden',false);

        loadMapPois ();
        $filterButton.attr("disabled", true);

    });

    $listToggle.click(function () {
        let $mapResult = $('.js-map-result');
        let $listResult = $('.js-list-result');
        let $filterButton = $('.js-filter-dropdown__toggle--price-sort');

        $(this).attr('hidden','hidden');
        $resultVersion.val('list');

        $listResult.attr('hidden',false);
        $mapResult.attr('hidden','hidden');
        $mapToggle.attr('hidden',false);

        lazyImgInit.initInScope($listResult);
        $('.js-slider').slick('reinit');

        $filterButton.attr("disabled", false);
    });
}