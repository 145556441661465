"use strict";

export function init () {
    let bottomBar = $(".js-sticky-bottom-container");

    $(window).scroll(function() {
        let scroll = $(window).scrollTop();

        if (scroll >= 500) {
            bottomBar.removeClass('sticky-bottom-container--hidden');
        } else {
            bottomBar.addClass('sticky-bottom-container--hidden');
        }
    });
}
