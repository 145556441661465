"use strict";

export function initInScope($scope) {
    $scope.find('.js-checkout-payment-method').on('change', function() {
        let $this = $(this);

        let method = this.value;

        $scope.find('.payment-method-holder .payment-method-row .js-payment-input').prop('checked',false);
        $scope.find('.payment-method-holder .payment-method-row').addClass('hidden');

        $scope.find('.payment-method-holder .payment-method-row[data-payment-method-key="' + method + '"]').removeClass('hidden');
        $scope.find('.payment-method-holder .payment-method-row[data-payment-method-key="' + method + '"] .js-payment-col:first .js-payment-input').prop('checked',true);

    });
}