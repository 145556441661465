/** @jsx h */

import { h, render } from 'preact';

function categoryCount(count) {
    let stars = [];
    for (let i = 0; i < count; i++) {
        stars.push(i)
    }
    return stars;
}

export default function (props) {

    console.log('props',props);


    let detailLink = _config.detailTranslation;
    let sammelanfrage = _config.sammelanfrageTranslation;

    return (
        <div>
        <div data-slide-id={props.objectid} className="h-100">
            <section className="teaser js-go-to-link teaser--regular teaser--acco h-100 d-flex flex-column">
                <div className="embed-responsive embed-responsive-3by2">
                    <img className="embed-responsive-item teaser__img" srcSet={props.imgUrl} alt=""/>
                    <span className="teaser__badge teaser__badge--with-checkbox">
                        <div className="custom-checkbox">
                            <label>
                                <input className="custom-checkbox__input js-multiple-inquiry-add" type="checkbox" name="group-inquiry[]" value={props.objectid} />
                                    <span className="custom-checkbox__box icon icon-check"></span>
                                    <span className="custom-checkbox__text">{sammelanfrage}</span>
                            </label>
                        </div>
                    </span>
                </div>
                <div className="teaser__body bg-white flex-grow-1">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="row no-gutters mb-2">
                                <div className="col-4">
                                    <div className="teaser__acco-category">
                                        {categoryCount(props.categoryCount).map(function (index) {
                                            return (
                                                <i className="icon icon-star"></i>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="col-4 text-center">
                                        <div class="teaser__acco-trust font-special">
                                            <div class="trust-you__item">
                                                <span class="trust-you__score">{props.trustYouScore}</span> / 100
                                            </div>
                                        </div>
                                </div>
                                <div className="col-4 text-right">
                                    <a href="javascript://" className="trip-planer-add float-right mr-2 js-trip-planer-add" data-id={props.objectid} data-typ={props.type}>
                                        <span className="trip-planer-add__visible-added">
                                            <span className="icon trip-planer__brandicon icon-brand-form"></span>
                                            <span className="trip-planer-add__icon icon icon-heart-filled"></span>
                                        </span>
                                        <span className="trip-planer-add__hidden-added">
                                            <span className="icon trip-planer__brandicon icon-brand-form"></span>
                                            <span className="trip-planer-add__icon icon icon-heart"></span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <h3 className="teaser__title my-2">
                                <a href={props.url}><span dangerouslySetInnerHTML={{__html: props.titleString}}></span></a>
                            </h3>
                            <div className="teaser__acco-town">
                                {props.meta}
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <a href={props.url} className="js-go-to-link__link btn btn-primary">{detailLink}</a>
                    </div>
                </div>
            </section>
        </div>
        </div>
);
}