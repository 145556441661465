"use strict";

export function initInScope($scope) {
    $scope.find('.js-meta-checkbox').on('change', function (evt) {
        let groupId = $(this).data('meta-checkbox');
        let subCheckBoxes = $('[data-meta-checkbox-group="' + groupId + '"]');
        subCheckBoxes.prop('checked', $(this).is(':checked'));
    });

    $scope.find('[data-meta-checkbox-group]').on('change', function (evt) {
        let groupId = $(this).data('meta-checkbox-group');
        let subCheckBoxes = $('[data-meta-checkbox-group="' + groupId + '"]');
        let metaCheckBox = $('.js-meta-checkbox[data-meta-checkbox="' + groupId + '"]');

        let isNoCheckBoxActive = true;
        subCheckBoxes.each(function () {
            if ($(this).is(':checked')) {
                isNoCheckBoxActive = false;
            }
        });

        metaCheckBox.prop('checked', !isNoCheckBoxActive);
    });
}