"use strict";

//import * as lazyImg from "@elements/lazy-img";
import initModulesInScope from "./initModulesInScope";

export function initInScope ($scope) {
    $scope.find('.js-search-form').on('submit', function (evt) {
        //console.log('123');
    });


    /* main quickfilter */
    $scope.find('.js-search-quickfilter__btn--main').on('click', function (evt) {
        let value = $(this).attr('data-value');
        evt.preventDefault();
        if($(this).hasClass('is-selected')) {
            $(this).closest('.js-search-quickfilter').find('input[data-value="' + value + '"]').prop('checked',false);
            $(this).removeClass('is-selected quick-filter--active');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input').prop('checked',false);
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input-all').prop('checked',false);
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn').removeClass('is-selected quick-filter--active');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn-all').removeClass('is-selected quick-filter--active');
            //$(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__dropdown').removeClass('is-open');
        } else {
            $(this).closest('.js-search-quickfilter').find('input[data-value="' + value + '"]').prop('checked','checked');
            $(this).addClass('is-selected quick-filter--active');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input').prop('checked','checked');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input-all').prop('checked','checked');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn').addClass('is-selected quick-filter--active');
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn-all').addClass('is-selected quick-filter--active');
            //$(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__dropdown').addClass('is-open');
        }
        submitForm();
    });



    /* category quickfilter */
    $scope.find('.js-search-quickfilter__cat-btn').on('click', function (evt) {
        let value = $(this).attr('data-value');
        evt.preventDefault();

        if($(this).hasClass('is-selected')) {
            $(this).closest('.js-search-quickfilter').find('input[data-value="' + value + '"]').prop('checked',false);
            $(this).removeClass('is-selected quick-filter--active');
        } else {
            $(this).closest('.js-search-quickfilter').find('input[data-value="' + value + '"]').prop('checked','checked');
            $(this).addClass('is-selected quick-filter--active');
        }

        let subCategories = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input');
        let checkedSubCategories = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input:checked');
        let toggleAllSubCat = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn-all');
        let toggleAllSubCatInput = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input-all');
        let mainCategoryInput = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__input');

        if(checkedSubCategories.length >= 1) {
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__btn--main').addClass('is-selected quick-filter--active');
        } else {
            $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__btn--main').removeClass('is-selected quick-filter--active');
        }

        /* toggle ALL btn*/
        if(checkedSubCategories.length == subCategories.length) {
            /*toggleAllSubCat.addClass('is-selected quick-filter--active');
            toggleAllSubCatInput.prop('checked','checked');
            mainCategoryInput.prop('checked','checked');*/
        } else {
            toggleAllSubCat.removeClass('is-selected quick-filter--active');
            toggleAllSubCatInput.prop('checked',false);
            mainCategoryInput.prop('checked',false);
        }

        submitForm();
    });



    /* all quickfilter */
    $scope.find('.js-search-quickfilter__cat-btn-all').on('click', function (evt) {
        let subCategories = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input');
        let subCategoriesBtns = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-btn');
        let toggleAllSubCatInput = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__cat-input-all');
        let mainCategoryBtn = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__btn--main');
        let mainCategoryInput = $(this).closest('.js-search-quickfilter__list').find('.js-search-quickfilter__input');

        evt.preventDefault();

        if($(this).hasClass('is-selected')) {
            subCategories.prop('checked',false);
            subCategoriesBtns.removeClass('is-selected quick-filter--active');
            $(this).removeClass('is-selected quick-filter--active');
            toggleAllSubCatInput.prop('checked',false);
            mainCategoryBtn.removeClass('is-selected quick-filter--active');
            mainCategoryInput.prop('checked',false);
        } else {
            subCategories.prop('checked','checked');
            subCategoriesBtns.addClass('is-selected quick-filter--active');
            $(this).addClass('is-selected quick-filter--active');
            toggleAllSubCatInput.prop('checked','checked');
            mainCategoryBtn.addClass('is-selected quick-filter--active');
            mainCategoryInput.prop('checked','checked');
        }

        submitForm();
    });


    /* mobile */

    /* all quickfilter */
    $scope.find('.js-toggle-xs-search-categories__btn').on('click', function (evt) {
        let filterGroup = $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__group');
        let filterInputs = $(this).closest('.js-toggle-xs-search-categories').find('input');
        if($(this).hasClass('is-selected')) {
            filterGroup.attr('hidden',false);
            filterInputs.prop('checked','checked');
        } else {
            filterGroup.attr('hidden','hidden');
            filterInputs.prop('checked',false);
        }
    });

    $scope.find('.js-xs-search-categories').on('change', function (evt) {
        let subCategories = $(this).closest('.js-toggle-xs-search-categories__group').find('input:not(.js-xs-search-categories-all)');
        let checkedSubCategories = $(this).closest('.js-toggle-xs-search-categories__group').find('input:not(.js-xs-search-categories-all):checked');

        /* toggle ALL btn */
        if(checkedSubCategories.length == subCategories.length) {
            /*$(this).closest('.js-toggle-xs-search-categories__group').find('.js-xs-search-categories-all').prop('checked','checked');
            $(this).closest('.js-toggle-xs-search-categories').find('.js-search-quickfilter__input').prop('checked','checked');*/
        } else {
            $(this).closest('.js-toggle-xs-search-categories__group').find('.js-xs-search-categories-all').prop('checked',false);
            $(this).closest('.js-toggle-xs-search-categories').find('.js-search-quickfilter__input').prop('checked',false);
        }

        if(!checkedSubCategories.length) {
            $(this).closest('.js-toggle-xs-search-categories__group').find('input').prop('checked',false);
            $(this).closest('.js-toggle-xs-search-categories').find('.js-search-quickfilter__input').prop('checked',false);
            $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__btn').removeClass('is-selected quick-filter--active');
            $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__group').attr('hidden','hidden');
        }
    });

    $scope.find('.js-xs-search-categories-all').on('change', function (evt) {
        if($(this).prop('checked')) {
            $(this).closest('.js-toggle-xs-search-categories__group').find('input').prop('checked','checked');
            $(this).closest('.js-toggle-xs-search-categories').find('.js-search-quickfilter__input').prop('checked','checked');
            $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__btn').addClass('is-selected quick-filter--active');
        } else {
            $(this).closest('.js-toggle-xs-search-categories__group').find('input').prop('checked',false);
            $(this).closest('.js-toggle-xs-search-categories').find('.js-search-quickfilter__input').prop('checked',false);
            $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__btn').removeClass('is-selected quick-filter--active');
            $(this).closest('.js-toggle-xs-search-categories').find('.js-toggle-xs-search-categories__group').attr('hidden','hidden');
        }
    });

    function submitForm() {
        if(matchMedia('(min-width: 768px)').matches){
            let $loading = $('.js-search-form__loading');
            let $content = $('.js-search-form__result');

            let baseUrl = $('.js-search-form').attr('data-ajax-action');
            let url = baseUrl + (baseUrl.indexOf('?') < 0 ? '?': '&') + $('.js-search-form').serialize();

            console.log(url);

            $loading.removeAttr('hidden');

            let pendingRequest = null;

            if (pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            pendingRequest = fetch(url, {
                headers: {Ajax: 1}
            })
                .then(response => response.text())
                .then(function (result) {
                    $content.html(result);
                    $loading.attr('hidden', 'hidden');
                    initModulesInScope($content);
                    //lazyImg.initInScope($content);

                    $loading.attr('hidden', 'hidden');
                    pendingRequest = null;
                }).catch(function (jqXHR, textStatus, errorThrown) {
                    if (textStatus !== 'abort') {
                        $loading.attr('hidden', 'hidden');
                        console.error(textStatus, errorThrown, jqXHR);
                    }
                    $loading.attr('hidden', 'hidden');
                    pendingRequest = null;
                });
        }
    }

}