"use strict";

export function initInScope($scope) {
    $scope.find('.js-rome-2-rio-form').on('submit', function (evt) {
        evt.preventDefault();

        let $this = $(this);
        let url = $this.attr('action') + '?' + encodeURIComponent($this.find('.js-rome-2-rio-form__from').val())
            + '/' + encodeURIComponent($this.find('.js-rome-2-rio-form__to').val());

        window.open(url);
    });
}